<template>
  <div>
    <a-modal v-model="visible" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{ form.id ? "编辑入库标签" : "新增入库标签" }}</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="material" label="产品">
            <material-select v-model="form.material" :defaultItem="form.material_item" />
          </a-form-model-item>
          <a-form-model-item prop="batch_number" label="批次编号">
            <a-input v-model="form.batch_number" />
          </a-form-model-item>
          <a-form-model-item prop="stock_in_month" label="入库月份">
            <a-month-picker v-model="form.stock_in_month" valueFormat="YYYY-MM-DD" placeholder="请选择月份" style="width: 100%" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { stockInLabelCreate, stockInLabelUpdate } from "@/api/label";
import rules from "./rules.js";

export default {
  components: {
    MaterialSelect: () => import("@/components/MaterialSelect/"),
  },
  props: ["visible", "form"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules,
      loading: false,
    };
  },
  methods: {
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? stockInLabelUpdate : stockInLabelCreate;
          func(this.form)
            .then((data) => {
              this.$message.success(this.form.id ? "修改成功" : "新增成功");
              this.$emit(this.form.id ? "update" : "create", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style scoped></style>
